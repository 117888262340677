body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-block {
  display: block;
}

:root {
  --orange: #ff9800;
  --light-orange: #fff3e0;
  --primary: rgb(81, 153, 228);
  --light-blue: #e3f2fd;
}
.flex-wrap {
  display: flex;
}
.flex-wrap.center {
  justify-content: center;
  align-items: center;
}
.flex-wrap.horizontal {
  align-items: center;
}
.flex-wrap.column {
  flex-flow: column nowrap;
}
.login-section {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
.logo-login {
  width: 200px;
  object-fit: contain;
}
.login-section h4 {
  font-size: 40px;
  margin: 0;
  font-weight: 100;
  margin-bottom: 20px;
}
.login-section .form-wrapper {
  width: 400px;
  max-width: 100%;
}
.login-section button {
  height: 50px;
  margin-top: 32px;
}
.login-section .input-wrapper {
  margin-bottom: 0;
}

.profile-section .form-wrapper {
  width: 100%;
}

.wrapper {
  margin-left: 240px;
}
.wrapper.notLoggedIn {
  margin-left: 0;
}
.profile-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.bg-test {
  background-color: red;
}

.padding-container {
  padding: 20px 0;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.token-wrapper {
  justify-content: space-between;
  align-items: center;
}
.token-wrapper:not(:first-child) {
  border-top: 1px solid #ddd;
}
.token-wrapper::last-child {
  background-color: red;
}

.sub-title-space {
  width: 70px;
  display: inline-block;
}
.badge {
  padding: 5px 10px;
  border-radius: 5px;
}
.badge.blue {
  background-color: var(--light-blue);
}
.badge.orange {
  background-color: var(--light-orange);
}
.input-token {
  height: 56px;
}
.token-page .MuiFormControl-marginNormal {
  margin-top: 0 !important;
}
.navlink {
  text-decoration: none;
  color: inherit;
}
.text-center {
  text-align: center;
}
.text-danger {
  color: red;
}
.cursor {
  cursor: pointer;
}
.icon-copy {
  cursor: pointer;
  color: #999;
}
.hidden {
  display: none;
}
.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 5s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  background-color: white;
  height: 5px;
}
.MuiDrawer-paper {
  z-index: 1202 !important;
}
.form-fixed-wrap {
  position: relative;
  height: fit-content;
}
.form-fixed-wrap .icon-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-25%);
}
.flex-right {
  justify-content: flex-end;
  align-items: center;
}
.no-margin {
  margin: 0;
}
.text-bold {
  font-weight: 900;
}
